import { XCircleIcon } from "@heroicons/react/solid";

interface Props {
  messages: string[];
}

export default function PageErrorBanner(props: Props) {
  const { messages } = props;
  return (
    <div className="max-w-7xl mx-auto px-6 py-8" data-test="page-error">
      <div className="bg-red-50 border-l-4 border-red-400 p-4 shadow">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              {messages.length > 1
                ? `There were ${messages.length} errors with your request:`
                : `There was an error with your request:`}
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc pl-5 space-y-1">
                {messages.map((text, i) => (
                  <li key={i}>{text}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
