import { LockClosedIcon } from "@heroicons/react/solid";
import bodyParser from "body-parser";
import ServerCookies from "cookies";
import Logo from "lib/components/Logo";
import PageErrorBanner from "lib/components/PageErrorBanner";
import { captureException } from "lib/errors";
import { loginUser } from "lib/server/internal";
import { GetServerSideProps, NextPage } from "next";
import Link from "next/link";
import { promisify } from "util";

enum PAGE_STATE {
  LOGIN,
  ERROR,
}

interface Props {
  state: PAGE_STATE;
}

const Page: NextPage<Props> = (props) => {
  const { state } = props;
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <Link href="/">
            <a className="flex flex-row justify-center">
              <Logo />
            </a>
          </Link>
          <div className="max-w-7xl mx-auto py-2">
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <p className="text-sm text-yellow-700">
                Currently open in private beta only. If interested in a demo,
                please consider{" "}
                <Link href="/contact">
                  <a className="font-medium underline text-yellow-700 hover:text-yellow-600">
                    contacting
                  </a>
                </Link>{" "}
                us.
              </p>
            </div>
          </div>
          {state === PAGE_STATE.ERROR && (
            <PageErrorBanner messages={["Unknown login"]} />
          )}
          <h2 className="mt-6 text-center text-3xl font-light text-white">
            Log in{" "}
            <button
              onClick={() => {
                throw new Error("from login");
              }}
            >
              to
            </button>{" "}
            your account
          </h2>
        </div>
        <form
          className="mt-8 space-y-6"
          action=""
          method="POST"
          data-test="login-form"
        >
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-300 focus:border-primary-300 focus:z-10 sm:text-sm"
                placeholder="Email address"
                data-test="email-input"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-300 focus:border-primary-300 focus:z-10 sm:text-sm"
                placeholder="Password"
                data-test="password-input"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-primary-500 focus:ring-primary-300 border-gray-300 rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-300"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <a
                href="#"
                className="font-medium text-primary-500 hover:text-primary-300"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-300 hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300"
              data-test="submit-btn"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-primary-300 group-hover:text-primary-100"
                  aria-hidden="true"
                />
              </span>
              Log in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Page;

const getBody = promisify(bodyParser.urlencoded({ extended: false }));

export const getServerSideProps: GetServerSideProps<Props> = async function (
  ctx
) {
  const { req, res } = ctx;
  const cookies = new ServerCookies(req, res);

  if (req.method === "GET") {
    // Clears cookie
    cookies.set("token", "", { domain: "localhost" });
  } else if (req.method === "POST") {
    // TODO: Should validate that POST comes from client?
    await getBody(req, res);
    let { body } = req as typeof req & {
      body: {
        email: string;
        password: string;
      };
    };

    let loginRes: Response;
    try {
      loginRes = await loginUser({
        username: body.email,
        password: body.password,
      });
    } catch (err) {
      captureException(err);
      return { props: { state: PAGE_STATE.ERROR } };
    }

    if (!loginRes.ok) {
      return { props: { state: PAGE_STATE.ERROR } };
    }
    const { token } = (await loginRes.json()) as {
      token: string;
    };

    // Nginx will rewrite on server to correct domain
    cookies.set("token", token, {
      domain: "localhost",
    });

    return {
      redirect: { destination: "/app/nodepools", permanent: false },
    };
  }
  return { props: { state: PAGE_STATE.LOGIN } };
};
